import { ObjectId } from "mongodb/lib/bson";

import type { AreaData, BlockData } from "@sunblocks/game";
import { zArea } from "@sunblocks/game";

import { area3 } from "./area-3";
import {
  lBottomLeft,
  lTopRight,
  longTRight,
  longTUp,
  plus,
  rectangle,
  s,
  shortLBottomLeft,
  shortLBottomRight,
  shortLTopLeft,
  shortLTopRight,
  sink,
  source,
  tLeft,
  tRight,
} from "./shapes";

const level3Ids = area3.levels.map(({ _id }) => _id);

export const area4 = zArea.parse({
  _id: new ObjectId("66bbc8a83a67736625ed2586"),
  models: {
    all: { model: { cell: true, active: true } },
  },
  background: "ground",
  levels: [
    {
      _id: new ObjectId("66bbc8b53a67736625ed2666"),
      tutorial: [
        { _key: "block-0-2-0/0", position: [0, 1] },
        { _key: "block-2-0-0/0", position: [2, 1] },
      ],
      condition: {
        type: "levels",
        count: 12,
        levelIds: level3Ids,
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1], active: true },
        { position: [0, 2] },
        { position: [1, 1], active: true },
        { position: [2, 0] },
        { position: [2, 1], active: true },
        { position: [2, 2] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 0], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...sink },
        ...(
          [
            { initialPosition: [1, 1], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed2667"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed2666")],
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 0] },
        { position: [1, 1], active: true },
        { position: [2, 1], active: true },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1], active: true },
        { position: [4, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 1], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed2668"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed2666")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0], active: true },
        { position: [2, 1], active: true },
        { position: [2, 2] },
        { position: [3, 0], active: true },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 0], active: true },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [7, 0], active: true },
        { position: [7, 1], active: true },
        { position: [7, 2], active: true },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source },
        { initialPosition: [0, 2], ...rectangle(3, 1), active: false },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(2, 2), active: false },
        { initialPosition: [5, 0], ...tRight },
        { initialPosition: [5, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 1], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed2669"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed2666")],
      },
      cells: [
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 2], active: true },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 2], active: true },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 2], active: true },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [8, 3] },
      ],
      blocks: [
        { initialPosition: [1, 2], ...source },
        { initialPosition: [2, 2], ...shortLTopRight },
        { initialPosition: [2, 4], ...rectangle(1, 1) },
        { initialPosition: [3, 5], ...rectangle(1, 2), active: false },
        { initialPosition: [5, 4], ...rectangle(2, 1) },
        { initialPosition: [8, 3], ...sink },
        ...(
          [
            { initialPosition: [4, 2], ...rectangle(1, 1) },
            { initialPosition: [4, 3], ...rectangle(1, 1) },
            { initialPosition: [4, 4], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed266a"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed2666")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0], active: true },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0], active: true },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0], active: true },
        { position: [4, 1] },
        { position: [4, 2], active: true },
        { position: [4, 3], active: true },
        { position: [4, 4], active: true },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 6] },
        { position: [7, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...shortLTopRight },
        { initialPosition: [0, 4], ...rectangle(1, 2) },
        { initialPosition: [1, 3], ...shortLTopRight, active: false },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
        { initialPosition: [2, 5], ...shortLBottomLeft },
        { initialPosition: [4, 0], ...rectangle(1, 2) },
        { initialPosition: [7, 6], ...sink },
        ...(
          [
            { initialPosition: [3, 0], ...rectangle(1, 1) },
            { initialPosition: [3, 1], ...rectangle(1, 1) },
            { initialPosition: [3, 2], ...rectangle(1, 1) },
            { initialPosition: [3, 3], ...rectangle(1, 1) },
            { initialPosition: [3, 4], ...rectangle(1, 1) },
            { initialPosition: [4, 3], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed266b"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed2666")],
      },
      cells: [
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6], active: true },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6], active: true },
        { position: [4, 7], active: true },
        { position: [4, 8], active: true },
        { position: [4, 9] },
        { position: [4, 10] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [5, 9] },
        { position: [5, 10] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4], active: true },
        { position: [6, 5], active: true },
        { position: [6, 6], active: true },
        { position: [6, 7], active: true },
        { position: [6, 8] },
        { position: [6, 9] },
        { position: [6, 10] },
        { position: [7, 4], active: true },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 4], active: true },
        { position: [8, 5], active: true },
        { position: [8, 6] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
      ],
      blocks: [
        { initialPosition: [0, 4], ...source },
        { initialPosition: [0, 6], ...rectangle(3, 1) },
        { initialPosition: [1, 5], ...rectangle(3, 1), active: false },
        { initialPosition: [4, 0], ...rectangle(1, 1) },
        { initialPosition: [4, 4], ...rectangle(3, 3), active: false },
        { initialPosition: [4, 7], ...rectangle(2, 2), active: false },
        { initialPosition: [4, 9], ...lTopRight },
        { initialPosition: [5, 0], ...s, active: false },
        { initialPosition: [5, 3], ...rectangle(2, 1), active: false },
        { initialPosition: [5, 9], ...rectangle(1, 1) },
        { initialPosition: [6, 7], ...rectangle(1, 3) },
        { initialPosition: [7, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 6], ...rectangle(2, 1) },
        { initialPosition: [8, 4], ...shortLTopLeft, active: false },
        { initialPosition: [9, 4], ...s },
        { initialPosition: [10, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed266c"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed2666")],
      },
      cells: [
        { position: [0, 3] },
        { position: [1, 3] },
        { position: [1, 4], active: true },
        { position: [1, 5], active: true },
        { position: [1, 6], active: true },
        { position: [1, 7], active: true },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 7], active: true },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 7], active: true },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 7], active: true },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 7], active: true },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 7], active: true },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 7], active: true },
        { position: [8, 3] },
        { position: [8, 4], active: true },
        { position: [8, 5], active: true },
        { position: [8, 6], active: true },
        { position: [8, 7], active: true },
        { position: [9, 3] },
      ],
      blocks: [
        { initialPosition: [0, 3], ...source },
        { initialPosition: [2, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 4], ...shortLBottomLeft, active: false },
        { initialPosition: [3, 2], ...rectangle(1, 2), active: false },
        { initialPosition: [4, 3], ...rectangle(1, 1) },
        { initialPosition: [4, 4], ...rectangle(1, 1) },
        { initialPosition: [5, 2], ...shortLBottomLeft, active: false },
        { initialPosition: [5, 3], ...rectangle(1, 1) },
        { initialPosition: [5, 4], ...rectangle(1, 1) },
        { initialPosition: [5, 4], ...shortLBottomRight, active: false },
        { initialPosition: [7, 3], ...rectangle(2, 2), active: false },
        { initialPosition: [9, 3], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed266d"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed2666")],
      },
      cells: [
        { position: [0, 3] },
        { position: [1, 3] },
        { position: [1, 4], active: true },
        { position: [1, 5], active: true },
        { position: [1, 6], active: true },
        { position: [1, 7], active: true },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 7], active: true },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 7], active: true },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 7], active: true },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 7], active: true },
        { position: [6, 3] },
        { position: [6, 4], active: true },
        { position: [6, 5], active: true },
        { position: [6, 6], active: true },
        { position: [6, 7], active: true },
        { position: [7, 0], active: true },
        { position: [7, 1], active: true },
        { position: [7, 2], active: true },
        { position: [7, 3], active: true },
        { position: [7, 4] },
        { position: [8, 0], active: true },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [9, 0], active: true },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [10, 0], active: true },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [11, 0], active: true },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [12, 0], active: true },
        { position: [12, 1], active: true },
        { position: [12, 2], active: true },
        { position: [12, 3], active: true },
        { position: [12, 4] },
        { position: [13, 4] },
      ],
      blocks: [
        { initialPosition: [0, 3], ...source },
        { initialPosition: [1, 3], ...tLeft, active: false },
        { initialPosition: [1, 5], ...rectangle(1, 2), active: false },
        { initialPosition: [3, 2], ...shortLBottomLeft, active: false },
        { initialPosition: [3, 5], ...rectangle(2, 1), active: false },
        { initialPosition: [4, 3], ...shortLBottomRight, active: false },
        { initialPosition: [6, 3], ...rectangle(2, 2), active: false },
        { initialPosition: [6, 6], ...rectangle(1, 2), active: false },
        { initialPosition: [9, 2], ...rectangle(1, 1) },
        { initialPosition: [9, 3], ...rectangle(1, 1) },
        { initialPosition: [9, 4], ...rectangle(1, 1) },
        { initialPosition: [10, 2], ...rectangle(1, 1) },
        { initialPosition: [10, 3], ...rectangle(1, 1) },
        { initialPosition: [10, 4], ...rectangle(1, 1) },
        { initialPosition: [10, 5], ...rectangle(1, 1) },
        { initialPosition: [13, 4], ...sink },
        ...(
          [
            { initialPosition: [7, 2], ...rectangle(1, 1) },
            { initialPosition: [12, 1], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed266e"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed2666")],
      },
      cells: [
        { position: [0, 0], active: true },
        { position: [0, 1], active: true },
        { position: [0, 2], active: true },
        { position: [0, 3], active: true },
        { position: [0, 4], active: true },
        { position: [0, 5], active: true },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [7, 0], active: true },
        { position: [7, 1], active: true },
        { position: [7, 2], active: true },
        { position: [7, 3], active: true },
        { position: [7, 4], active: true },
        { position: [7, 5], active: true },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...rectangle(1, 2), active: false },
        { initialPosition: [1, 0], ...plus, active: false },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...plus },
        { initialPosition: [1, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 2], ...rectangle(1, 2), active: false },
        { initialPosition: [4, 0], ...plus },
        { initialPosition: [4, 2], ...rectangle(1, 2), active: false },
        { initialPosition: [4, 3], ...plus, active: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 3], ...rectangle(1, 1) },
        { initialPosition: [7, 2], ...rectangle(1, 2), active: false },
        { initialPosition: [7, 5], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed266f"),
      tutorial: [
        { _key: "block-0-0-0/0", position: [0, 1] },
        { _key: "block-2-1-0/0", position: [1, 1] },
        { _key: "block-2-2-0/0", position: [2, 1] },
      ],
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed2666")],
      },
      cells: [
        { position: [0, 0], source: true },
        { position: [0, 1], active: true },
        { position: [1, 1] },
        { position: [2, 1], active: true },
        { position: [2, 2], active: true, sink: true },
      ],
      blocks: [
        { initialPosition: [0, 0], ...rectangle(1, 1) },
        { initialPosition: [2, 1], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed2670"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed266f")],
      },
      cells: [
        { position: [0, 0], source: true },
        { position: [0, 1], active: true },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1], active: true },
        { position: [3, 2], active: true },
        { position: [3, 3], active: true, sink: true },
      ],
      blocks: [
        { initialPosition: [0, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 3], ...rectangle(1, 1) },
        { initialPosition: [1, 0], ...rectangle(1, 1) },
        { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 2], ...rectangle(1, 1) },
        { initialPosition: [1, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 3], ...rectangle(1, 1), active: false },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed2671"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed266f")],
      },
      cells: [
        { position: [0, 3] },
        { position: [1, 2] },
        { position: [1, 3], active: true },
        { position: [1, 4], active: true },
        { position: [2, 1] },
        { position: [2, 2], source: true },
        { position: [2, 3], active: true },
        { position: [2, 4] },
        { position: [2, 5], active: true },
        { position: [3, 0] },
        { position: [3, 1], active: true },
        { position: [3, 2], active: true },
        { position: [3, 3] },
        { position: [3, 4], active: true },
        { position: [3, 5], active: true },
        { position: [3, 6] },
        { position: [4, 1], active: true },
        { position: [4, 2] },
        { position: [4, 3], active: true },
        { position: [4, 4], active: true, sink: true },
        { position: [4, 5] },
        { position: [5, 2], active: true },
        { position: [5, 3], active: true },
        { position: [5, 4] },
        { position: [6, 3] },
      ],
      blocks: [
        { initialPosition: [1, 1], ...shortLBottomRight, active: false },
        { initialPosition: [1, 3], ...plus, active: false },
        { initialPosition: [3, 2], ...rectangle(2, 2), active: false },
        { initialPosition: [4, 1], ...rectangle(1, 1) },
        { initialPosition: [4, 4], ...shortLTopLeft, active: false },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [6, 3], ...rectangle(1, 1) },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed2672"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed266f")],
      },
      cells: [
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4], active: true },
        { position: [0, 5] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3], source: true },
        { position: [1, 4], active: true },
        { position: [1, 5] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3], active: true },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3], active: true },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3], active: true },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [7, 1] },
        { position: [7, 2], active: true },
        { position: [7, 3], active: true, sink: true },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [8, 1] },
        { position: [8, 2], active: true },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
      ],
      blocks: [
        { initialPosition: [2, 3], ...rectangle(1, 1) },
        { initialPosition: [2, 4], ...lTopRight },
        { initialPosition: [3, 2], ...lBottomLeft },
        { initialPosition: [3, 3], ...lTopRight, active: false },
        { initialPosition: [4, 1], ...lBottomLeft, active: false },
        { initialPosition: [6, 3], ...rectangle(1, 1) },
        { initialPosition: [6, 4], ...rectangle(1, 2) },
        { initialPosition: [7, 4], ...rectangle(1, 2) },
        ...(
          [
            { initialPosition: [1, 3], ...rectangle(1, 1) },
            { initialPosition: [1, 4], ...rectangle(1, 1) },
            { initialPosition: [7, 2], ...rectangle(1, 1) },
            { initialPosition: [7, 3], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed2673"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed266f")],
      },
      cells: [
        { position: [0, 0], source: true },
        { position: [0, 1], active: true },
        { position: [0, 2], active: true },
        { position: [0, 3] },
        { position: [0, 4], active: true },
        { position: [0, 5], active: true },
        { position: [0, 6], active: true },
        { position: [0, 7] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [2, 0] },
        { position: [2, 1], active: true },
        { position: [2, 2], active: true },
        { position: [2, 3], active: true },
        { position: [2, 4] },
        { position: [2, 5], active: true },
        { position: [2, 6], active: true },
        { position: [2, 7], active: true },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [4, 0], active: true },
        { position: [4, 1] },
        { position: [4, 2], active: true },
        { position: [4, 3], active: true },
        { position: [4, 4], active: true },
        { position: [4, 5] },
        { position: [4, 6], active: true },
        { position: [4, 7], active: true },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [6, 0], active: true },
        { position: [6, 1], active: true },
        { position: [6, 2] },
        { position: [6, 3], active: true },
        { position: [6, 4], active: true },
        { position: [6, 5], active: true },
        { position: [6, 6] },
        { position: [6, 7], active: true },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [8, 0], active: true },
        { position: [8, 1], active: true },
        { position: [8, 2], active: true },
        { position: [8, 3] },
        { position: [8, 4], active: true },
        { position: [8, 5], active: true },
        { position: [8, 6], active: true },
        { position: [8, 7], active: true, sink: true },
      ],
      blocks: [
        { initialPosition: [0, 5], ...rectangle(5, 1) },
        { initialPosition: [1, 2], ...rectangle(3, 3), active: false },
        { initialPosition: [3, 1], ...rectangle(5, 1) },
        { initialPosition: [5, 3], ...longTUp, active: false },
        { initialPosition: [5, 5], ...rectangle(2, 2), active: false },
        { initialPosition: [7, 6], ...rectangle(2, 2), active: false },
        { initialPosition: [8, 0], ...rectangle(1, 5), active: false },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed2674"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed266f")],
      },
      cells: [
        { position: [0, 1], source: true },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [1, 1] },
        { position: [1, 3] },
        { position: [1, 4], active: true },
        { position: [1, 5], active: true },
        { position: [1, 6], active: true },
        { position: [1, 7], active: true },
        { position: [2, 1], active: true },
        { position: [2, 3] },
        { position: [2, 4], active: true },
        { position: [2, 5] },
        { position: [2, 7] },
        { position: [3, 0] },
        { position: [3, 1], active: true },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4], active: true },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7], active: true },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1], active: true },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4], active: true, sink: true },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7], active: true },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1], active: true },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7], active: true },
        { position: [5, 8] },
        { position: [6, 1], active: true },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 7], active: true },
        { position: [7, 1], active: true },
        { position: [7, 2], active: true },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5], active: true },
        { position: [7, 6], active: true },
        { position: [7, 7], active: true },
        { position: [8, 3], active: true },
        { position: [8, 4], active: true },
        { position: [8, 5], active: true },
      ],
      blocks: [
        { initialPosition: [0, 4], ...rectangle(3, 1) },
        { initialPosition: [1, 3], ...rectangle(2, 1), active: false },
        { initialPosition: [3, 0], ...rectangle(2, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 2), active: false },
        { initialPosition: [3, 3], ...rectangle(3, 3), active: false },
        { initialPosition: [3, 7], ...shortLTopRight },
        { initialPosition: [4, 2], ...rectangle(2, 1), active: false },
        { initialPosition: [4, 6], ...shortLBottomLeft, active: false },
        { initialPosition: [5, 0], ...rectangle(1, 2), active: false },
        { initialPosition: [6, 4], ...rectangle(3, 1) },
        { initialPosition: [6, 5], ...rectangle(2, 1), active: false },
      ],
    },
    {
      _id: new ObjectId("66bbc8b53a67736625ed2675"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc8b53a67736625ed266f")],
      },
      cells: [
        { position: [0, 0], active: true, sink: true },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5], active: true, sink: true },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 0], active: true, sink: true },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5], active: true, sink: true },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [0, 1], ...longTRight, active: false },
        { initialPosition: [0, 3], ...rectangle(1, 2), active: false },
        { initialPosition: [1, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 5], ...rectangle(2, 1), active: false },
        { initialPosition: [2, 1], ...plus, active: false },
        { initialPosition: [2, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 4], ...rectangle(5, 1), active: false },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 5], ...rectangle(2, 1), active: false },
        { initialPosition: [4, 0], ...source, mobile: true },
        { initialPosition: [4, 1], ...longTRight, active: false },
        { initialPosition: [4, 3], ...source, mobile: true },
        { initialPosition: [5, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 5], ...source, mobile: true },
      ],
    },
  ],
} satisfies AreaData);
