import { ObjectId } from "mongodb/lib/bson";

import type { AreaData, BlockData } from "@sunblocks/game";
import { zArea } from "@sunblocks/game";

import { area2 } from "./area-2";
import {
  lBottomRight,
  lTopLeft,
  longLBottomRight,
  longTRight,
  longTUp,
  plus,
  rectangle,
  reverseLBottomLeft,
  reverseLTopLeft,
  shortLBottomLeft,
  shortLBottomRight,
  shortLTopLeft,
  shortLTopRight,
  sink,
  source,
  tRight,
} from "./shapes";

const level2Ids = area2.levels.map(({ _id }) => _id);

export const area3 = zArea.parse({
  _id: new ObjectId("66bbc82052d3058e6c923c52"),
  models: {
    all: { model: { mobile: false } },
    wonBest: { model: { mobile: false } },
  },
  levels: [
    {
      _id: new ObjectId("66bbc84652d3058e6c923cd3"),
      tutorial: [
        { _key: "block-0-0-0/0", position: [0, 1] },
        { _key: "block-2-2-0/0", position: [2, 1] },
      ],
      condition: {
        type: "levels",
        count: 12,
        levelIds: level2Ids,
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [1, 1] },
        { position: [2, 1] },
        { position: [2, 2] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [1, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 2], ...sink, mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cd4"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 1] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source, mobile: true },
        { initialPosition: [1, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 0], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 1], ...sink, mobile: true },
        ...(
          [
            { initialPosition: [2, 1], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cd5"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [0, 1], ...source },
        { initialPosition: [0, 2], ...rectangle(3, 1) },
        { initialPosition: [3, 0], ...rectangle(2, 2), active: false },
        { initialPosition: [5, 0], ...tRight },
        { initialPosition: [5, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 2], ...rectangle(1, 1) },
        { initialPosition: [7, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 2], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cd6"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [0, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 3], ...sink, mobile: true },
        ...(
          [
            { initialPosition: [2, 1], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cd7"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [4, 1] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...source, mobile: true },
        { initialPosition: [0, 3], ...sink },
        { initialPosition: [0, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 4], ...rectangle(1, 1) },
        { initialPosition: [2, 2], ...rectangle(2, 2) },
        ...(
          [
            { initialPosition: [1, 3], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cd8"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 1], ...rectangle(1, 2) },
        { initialPosition: [0, 3], ...rectangle(2, 1), active: false },
        { initialPosition: [0, 4], ...rectangle(2, 1) },
        { initialPosition: [1, 1], ...rectangle(1, 2), active: false },
        { initialPosition: [2, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 3], ...rectangle(2, 2) },
        { initialPosition: [6, 0], ...sink },
        { initialPosition: [6, 2], ...rectangle(1, 1), mobile: false },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cd9"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...shortLBottomRight, active: false },
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [0, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 1], ...plus, active: false },
        { initialPosition: [2, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 3], ...rectangle(2, 1), active: false },
        { initialPosition: [4, 0], ...sink, mobile: true },
        { initialPosition: [4, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 4], ...sink, mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cda"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
      ],
      blocks: [
        { initialPosition: [0, 1], ...shortLTopLeft, active: false },
        { initialPosition: [0, 3], ...rectangle(1, 2), active: false },
        { initialPosition: [0, 4], ...shortLBottomRight, active: false },
        { initialPosition: [1, 0], ...source, mobile: true },
        { initialPosition: [1, 6], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 4], ...shortLBottomRight, active: false },
        { initialPosition: [3, 0], ...shortLTopRight },
        { initialPosition: [3, 6], ...sink, mobile: true },
        { initialPosition: [4, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 5], ...shortLBottomLeft },
        { initialPosition: [5, 1], ...shortLTopLeft },
        { initialPosition: [5, 3], ...shortLBottomRight, active: false },
        ...(
          [
            { initialPosition: [2, 2], ...rectangle(1, 1) },
            { initialPosition: [3, 3], ...rectangle(1, 1) },
            { initialPosition: [4, 4], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cdb"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [9, 0] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [10, 0] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [1, 0], ...rectangle(3, 1), active: false },
        { initialPosition: [1, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 4], ...rectangle(3, 1) },
        { initialPosition: [3, 2], ...rectangle(5, 1) },
        { initialPosition: [4, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 5], ...rectangle(1, 2), active: false },
        { initialPosition: [5, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 0], ...rectangle(3, 1), active: false },
        { initialPosition: [6, 3], ...rectangle(1, 2), active: false },
        { initialPosition: [6, 5], ...rectangle(1, 2), active: false },
        { initialPosition: [8, 2], ...rectangle(2, 1), active: false },
        { initialPosition: [8, 4], ...rectangle(1, 2), active: false },
        { initialPosition: [9, 4], ...rectangle(2, 1) },
        { initialPosition: [9, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 0], ...rectangle(1, 3) },
        { initialPosition: [10, 3], ...rectangle(1, 1) },
        { initialPosition: [10, 6], ...sink },
        ...(
          [
            { initialPosition: [1, 3], ...rectangle(1, 1) },
            { initialPosition: [1, 5], ...rectangle(1, 1) },
            { initialPosition: [3, 1], ...rectangle(1, 1) },
            { initialPosition: [3, 3], ...rectangle(1, 1) },
            { initialPosition: [3, 5], ...rectangle(1, 1) },
            { initialPosition: [5, 1], ...rectangle(1, 1) },
            { initialPosition: [5, 5], ...rectangle(1, 1) },
            { initialPosition: [7, 1], ...rectangle(1, 1) },
            { initialPosition: [7, 3], ...rectangle(1, 1) },
            { initialPosition: [7, 5], ...rectangle(1, 1) },
            { initialPosition: [9, 1], ...rectangle(1, 1) },
            { initialPosition: [9, 3], ...rectangle(1, 1) },
          ] satisfies BlockData[]
        ).map((block) => ({ ...block, mobile: false, active: false })),
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cdc"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 2], ...shortLBottomRight },
        { initialPosition: [0, 5], ...longLBottomRight },
        { initialPosition: [1, 1], ...rectangle(5, 1) },
        { initialPosition: [2, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 7], ...shortLBottomRight },
        { initialPosition: [3, 3], ...reverseLBottomLeft, active: false },
        { initialPosition: [4, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 3], ...rectangle(1, 3), active: false },
        { initialPosition: [6, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 2], ...lTopLeft, active: false },
        { initialPosition: [6, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 1], ...lBottomRight, active: false },
        { initialPosition: [8, 8], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cdd"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [12, 5] },
        { position: [12, 6] },
      ],
      blocks: [
        { initialPosition: [1, 1], ...source },
        { initialPosition: [1, 5], ...rectangle(2, 2) },
        { initialPosition: [2, 1], ...rectangle(1, 2) },
        { initialPosition: [3, 3], ...rectangle(2, 2), active: false },
        { initialPosition: [3, 5], ...rectangle(1, 1) },
        { initialPosition: [5, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 3], ...rectangle(1, 3) },
        { initialPosition: [7, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 3], ...rectangle(3, 1) },
        { initialPosition: [7, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 7], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 4], ...rectangle(2, 2), active: false },
        { initialPosition: [10, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [10, 5], ...rectangle(2, 2), active: false },
        { initialPosition: [12, 6], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cde"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [8, 3] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 6], ...source },
        { initialPosition: [1, 0], ...rectangle(1, 2), active: false },
        { initialPosition: [1, 6], ...rectangle(1, 1) },
        { initialPosition: [2, 5], ...reverseLTopLeft, active: false },
        { initialPosition: [3, 0], ...shortLTopLeft, active: false },
        { initialPosition: [3, 4], ...rectangle(1, 1) },
        { initialPosition: [3, 6], ...rectangle(2, 1), active: false },
        { initialPosition: [4, 1], ...shortLTopLeft, active: false },
        { initialPosition: [5, 2], ...reverseLTopLeft, active: false },
        { initialPosition: [5, 5], ...rectangle(1, 1) },
        { initialPosition: [6, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 3], ...sink, mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923cdf"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 6] },
        { position: [0, 7] },
        { position: [0, 8] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 6] },
        { position: [1, 7] },
        { position: [1, 8] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [2, 7] },
        { position: [2, 8] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 6] },
        { position: [3, 7] },
        { position: [3, 8] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 6] },
        { position: [4, 7] },
        { position: [4, 8] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [5, 7] },
        { position: [5, 8] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 8] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 8] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source },
        { initialPosition: [0, 6], ...rectangle(1, 3) },
        { initialPosition: [1, 2], ...rectangle(2, 1), active: false },
        { initialPosition: [1, 8], ...rectangle(2, 1), active: false },
        { initialPosition: [2, 0], ...rectangle(2, 1), active: false },
        { initialPosition: [2, 3], ...rectangle(1, 3) },
        { initialPosition: [3, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 2], ...rectangle(2, 1) },
        { initialPosition: [3, 6], ...rectangle(1, 2), active: false },
        { initialPosition: [4, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [5, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 0], ...rectangle(2, 2) },
        { initialPosition: [6, 3], ...rectangle(1, 3), active: false },
        { initialPosition: [6, 5], ...longTUp },
        { initialPosition: [6, 7], ...rectangle(2, 2) },
        { initialPosition: [7, 2], ...rectangle(2, 2), active: false },
        { initialPosition: [7, 4], ...shortLTopLeft, active: false },
        { initialPosition: [8, 8], ...sink },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923ce0"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 5] },
        { position: [1, 3] },
        { position: [1, 5] },
        { position: [1, 7] },
        { position: [2, 3] },
        { position: [2, 5] },
        { position: [2, 7] },
        { position: [3, 1] },
        { position: [3, 3] },
        { position: [3, 5] },
        { position: [3, 7] },
        { position: [4, 1] },
        { position: [4, 3] },
        { position: [4, 5] },
        { position: [4, 7] },
        { position: [5, 1] },
        { position: [5, 3] },
        { position: [5, 5] },
        { position: [5, 7] },
        { position: [6, 1] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [6, 7] },
        { position: [6, 10] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [7, 7] },
        { position: [7, 9] },
        { position: [7, 10] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
        { position: [8, 7] },
        { position: [8, 8] },
        { position: [8, 9] },
        { position: [8, 10] },
        { position: [9, 1] },
        { position: [9, 2] },
        { position: [9, 3] },
        { position: [9, 4] },
        { position: [9, 5] },
        { position: [9, 6] },
        { position: [9, 7] },
        { position: [9, 8] },
        { position: [9, 9] },
        { position: [10, 1] },
        { position: [10, 2] },
        { position: [10, 3] },
        { position: [10, 4] },
        { position: [10, 5] },
        { position: [10, 6] },
        { position: [10, 7] },
        { position: [10, 8] },
        { position: [11, 2] },
        { position: [11, 3] },
        { position: [11, 4] },
        { position: [11, 5] },
        { position: [11, 6] },
        { position: [11, 7] },
      ],
      blocks: [
        { initialPosition: [0, 5], ...source, mobile: true },
        { initialPosition: [1, 3], ...source, mobile: true },
        { initialPosition: [1, 5], ...rectangle(2, 1) },
        { initialPosition: [1, 7], ...source, mobile: true },
        { initialPosition: [2, 3], ...rectangle(2, 1) },
        { initialPosition: [2, 7], ...rectangle(2, 1) },
        { initialPosition: [3, 1], ...source, mobile: true },
        { initialPosition: [3, 5], ...rectangle(2, 1) },
        { initialPosition: [4, 1], ...rectangle(2, 1) },
        { initialPosition: [4, 3], ...rectangle(2, 1) },
        { initialPosition: [4, 7], ...rectangle(2, 1) },
        { initialPosition: [6, 1], ...rectangle(2, 1) },
        { initialPosition: [6, 3], ...rectangle(1, 2), active: false },
        { initialPosition: [6, 5], ...rectangle(1, 3), active: false },
        { initialPosition: [6, 10], ...sink, mobile: true },
        { initialPosition: [7, 2], ...rectangle(1, 4), active: false },
        { initialPosition: [7, 9], ...shortLBottomRight },
        { initialPosition: [8, 3], ...rectangle(2, 2), active: false },
        { initialPosition: [8, 8], ...shortLBottomLeft },
        { initialPosition: [9, 5], ...rectangle(3, 3), active: false },
        { initialPosition: [10, 3], ...rectangle(2, 2), active: false },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923ce1"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [0, 6] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [1, 6] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [2, 6] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [3, 6] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [4, 6] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [5, 6] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
        { position: [6, 6] },
        { position: [7, 0] },
        { position: [7, 1] },
        { position: [7, 2] },
        { position: [7, 3] },
        { position: [7, 4] },
        { position: [7, 5] },
        { position: [7, 6] },
        { position: [8, 0] },
        { position: [8, 1] },
        { position: [8, 2] },
        { position: [8, 3] },
        { position: [8, 4] },
        { position: [8, 5] },
        { position: [8, 6] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [0, 1], ...rectangle(1, 1) },
        { initialPosition: [0, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [0, 6], ...sink, mobile: true },
        { initialPosition: [1, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [1, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [2, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 6], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [3, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [4, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 2], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 4], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [4, 6], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [5, 6], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [6, 6], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 0], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 1], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [7, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 4], ...rectangle(1, 1) },
        { initialPosition: [7, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [7, 6], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 0], ...sink, mobile: true },
        { initialPosition: [8, 1], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 2], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 3], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 4], ...rectangle(1, 1), active: false },
        { initialPosition: [8, 5], ...rectangle(1, 1), mobile: false },
        { initialPosition: [8, 6], ...sink, mobile: true },
      ],
    },
    {
      _id: new ObjectId("66bbc84652d3058e6c923ce2"),
      condition: {
        type: "levels",
        levelIds: [new ObjectId("66bbc84652d3058e6c923cd3")],
      },
      cells: [
        { position: [0, 0] },
        { position: [0, 1] },
        { position: [0, 2] },
        { position: [0, 3] },
        { position: [0, 4] },
        { position: [0, 5] },
        { position: [1, 0] },
        { position: [1, 1] },
        { position: [1, 2] },
        { position: [1, 3] },
        { position: [1, 4] },
        { position: [1, 5] },
        { position: [2, 0] },
        { position: [2, 1] },
        { position: [2, 2] },
        { position: [2, 3] },
        { position: [2, 4] },
        { position: [2, 5] },
        { position: [3, 0] },
        { position: [3, 1] },
        { position: [3, 2] },
        { position: [3, 3] },
        { position: [3, 4] },
        { position: [3, 5] },
        { position: [4, 0] },
        { position: [4, 1] },
        { position: [4, 2] },
        { position: [4, 3] },
        { position: [4, 4] },
        { position: [4, 5] },
        { position: [5, 0] },
        { position: [5, 1] },
        { position: [5, 2] },
        { position: [5, 3] },
        { position: [5, 4] },
        { position: [5, 5] },
        { position: [6, 0] },
        { position: [6, 1] },
        { position: [6, 2] },
        { position: [6, 3] },
        { position: [6, 4] },
        { position: [6, 5] },
      ],
      blocks: [
        { initialPosition: [0, 0], ...source, mobile: true },
        { initialPosition: [0, 1], ...longTRight, active: false },
        { initialPosition: [0, 3], ...rectangle(1, 2), active: false },
        { initialPosition: [1, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [1, 5], ...rectangle(2, 1), active: false },
        { initialPosition: [2, 1], ...plus, active: false },
        { initialPosition: [2, 3], ...rectangle(1, 1), active: false },
        { initialPosition: [2, 4], ...rectangle(5, 1), active: false },
        { initialPosition: [3, 0], ...rectangle(1, 1), active: false },
        { initialPosition: [3, 5], ...rectangle(2, 1), active: false },
        { initialPosition: [4, 0], ...sink, mobile: true },
        { initialPosition: [4, 1], ...longTRight, active: false },
        { initialPosition: [4, 3], ...sink, mobile: true },
        { initialPosition: [5, 5], ...rectangle(1, 1), active: false },
        { initialPosition: [6, 5], ...sink, mobile: true },
      ],
    },
  ],
} satisfies AreaData);
